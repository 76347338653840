import styled from 'styled-components'

const Container = styled.div`
  padding: 0 12px;
  margin: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-width: unset;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 720px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    max-width: 960px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    max-width: 1400px;
  }
`

export default Container
